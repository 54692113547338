body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
  color: black !important;
  opacity: 1;
}

input {
  background-color: rgba(245,245,245,0.35) !important;
  border: none !important;
}

button {
  color: #000000 !important;
  background-color: #F7941D !important;
  border-color: #F7941D !important;
}

label {
  color: white !important;
  opacity: 1;
}

#activationCode {
  height: 120px;
  /*need to align input text*/
}

#activationCode::placeholder {
  position: absolute;
}

#companyInfo {
 color: white !important;
}

/*#phoneInputField {
  background-color: rgba(245,245,245,0.35);
  border: none;
}

#buttonStyle {
  background-color: rgba(245,245,245,0.35);
  border: none;
}

#searchStyle {
  background-color: rgba(245,245,245,0.35);
  border: none;
}*/

/*#mainRow {
doesn't work
  height: 60vh;
}*/
